import { Autocomplete, Button, FormControl, Grid, InputAdornment, Modal, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import globalContext from "./globalContext";
import api from "../util/api";
import dateFormat, { masks } from "dateformat";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import AlertDialog from "./alertdialog";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '95%',
    maxWidth: '95%'
};

function StavkaRacunskeStavkeForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const setFormOpened = props.setFormOpened;
    const formStavka = props.formStavka;
    const setStavke = props.setStavke;
    const stavke = props.stavke;
    const racunskaStavkaID = props.racunskaStavkaID;
    const racunID = props.racunID;
    const refresh = props.refresh;
    const tipNaplatnostiNaplatnoBoja = props.tipNaplatnostiNaplatnoBoja;
    const tipNaplatnostiNenaplatnoBoja = props.tipNaplatnostiNenaplatnoBoja;
    const tipoviKorisnika = props.tipoviKorisnika;
    const fiksnaNaplata = props.fiksnaNaplata;
    const fiksnaNaplataKlijentID = props.fiksnaNaplataKlijentID;
    const fiksnaNaplataPredmetID = props.fiksnaNaplataPredmetID;
    const tipEvidencijeOptions = [{naziv:"Aktivnost", value:1}, {naziv:"Trošak", value:2}];
    const tipProvjerePromjeneCijene = props.tipProvjerePromjeneCijene;

    const [takeALook, setTakeALook] = useState(props.takeALook);
    const [loading, setLoading] = useState(true);
    const [datum, setDatum] = useState(new Date());
    const [klijenti, setKlijenti] = useState([]);
    //const [klijent, setKlijent] = useState(props.klijent);
    const [klijent, setKlijent] = useState(null);
    const [opis, setOpis] = useState("");
    const [predmeti, setPredmeti] = useState([]);
    const [predmet, setPredmet] = useState(null);
    const [zaposlenici, setZaposlenici] = useState([]);
    const [zaposlenik, setZaposlenik] = useState(null);
    const [kolicina, setKolicina] = useState(1);
    const [cijena, setCijena] = useState(Util.toStringDecimal(0));
    const [firmaValute, setFirmaValute] = useState([]);
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [tipoviNaplatnosti, setTipoviNaplatnosti] = useState([]);
    const [tipNaplatnosti, setTipNaplatnosti] = useState(null);
    const [tipoviOporezivosti, setTipoviOporezivosti] = useState([]);
    const [tipOporezivosti, setTipOporezivosti] = useState(null);
    const [tarifa, setTarifa] = useState("");
    const [tipNaplateID, setTipNaplateID] = useState(1);
    const [evidencijaDetaljID, setEvidencijaDetaljID] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [valid, setValid] = useState(false);
    const [promijeniCijenuZaPredmetUSvimStavkamaAlert, setPromijeniCijenuZaPredmetUSvimStavkamaAlert] = useState(false);
    const [promijeniCijenuZaPredmetUSvimStavkamaPriSpremanjuRacuna, setPromijeniCijenuZaPredmetUSvimStavkamaPriSpremanjuRacuna] = useState(false);
    const [promijeniCijenuTipKorisnikaID, setPromijeniCijenuTipKorisnikaID] = useState(0);
    const [pocetnaCijena, setPocetnaCijena] = useState(0);
    const [pocetniTipNaplatnosti, setPocetniTipNaplatnosti] = useState(null);
    const [pocetnaFirmaValuta, setPocetnaFirmaValuta] = useState(null);
    const [pocetniTipOporezivosti, setPocetniTipOporezivosti] = useState(null);
    const tipoviDosega = [{TipDosegaID: 1, Naziv: 'Primijeni na sve specifikacije ovog računa'}, {TipDosegaID: 2, Naziv: 'Primijeni samo na trenutnu specifikaciju ovog računa'}];
    const [tipDosega, setTipDosega] = useState(tipoviDosega[0]);
    const [promijeniCijenuKorisnikID, setPromijeniCijenuKorisnikID] = useState(null);
    const [tipEvidencije, setTipEvidencije] = useState({naziv:"Aktivnost", value:1});
    const [tipEvidencijeVisible, setTipEvidencijeVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        loadFormDetaljiSuccess();
    });

    function loadFormDetaljiSuccess() {
        setKlijenti(props.klijenti);
        setPredmeti(props.predmeti);
        setZaposlenici(props.zaposlenici);
        setFirmaValute(props.firmaValute);
        setTipoviNaplatnosti(props.tipoviNaplatnosti);
        setTipoviOporezivosti(props.tipoviOporezivosti);
        if (formStavka) {
            //postojeća stavka
            //učitati klijenta iz predmeta
            setKlijent(props.klijenti.find(k => k.KlijentID == formStavka.Predmet.KlijentID));
            setDatum(formStavka.Datum);
            setZaposlenik(formStavka.Korisnik);
            setFirmaValuta(formStavka.FirmaValuta);
            setPocetnaFirmaValuta(formStavka.FirmaValuta);
            setTipNaplatnosti(formStavka.TipNaplatnosti);
            setPocetniTipNaplatnosti(formStavka.TipNaplatnosti);
            setTipOporezivosti(formStavka.TipOporezivosti);
            setPocetniTipOporezivosti(formStavka.TipOporezivosti);
            setPredmet(formStavka.Predmet);
            setKolicina(formStavka.Kolicina);
            setOpis(formStavka.Opis);
            setTarifa(formStavka.Tarifa);
            setCijena(Util.toStringDecimal(formStavka.Iznos));
            setPocetnaCijena(formStavka.Iznos);
            setEvidencijaDetaljID(formStavka.EvidencijaDetaljID);
//            setTipEvidencije(formStavka.TipEvidencije);
            setTipEvidencije(tipEvidencijeOptions.find(eo=>eo.value == formStavka.TipEvidencijeID));
            setTipEvidencijeVisible(formStavka.EvidencijaDetaljID != null ? false : true);
            setLoading(false);
        } else {
            //nova stavka specifikacije
            setKlijent(props.klijent);
            setZaposlenik(global.drugiZaposlenik);
            setFirmaValuta(props.defaultFirmaValuta);
            setTipNaplatnosti(props.tipoviNaplatnosti.at(0));
            setTipOporezivosti(props.defaultTipOporezivosti);
            setTipEvidencije(tipEvidencijeOptions.find(eo=>eo.value == 1));
            setTipEvidencijeVisible(true);
            setLoading(false);
            if (fiksnaNaplata) {
                setKlijent(props.klijenti.find(k => k.KlijentID == fiksnaNaplataKlijentID));
                setPredmet(props.predmeti.find(p => p.PredmetID == fiksnaNaplataPredmetID));
            }
        }
    }


    function loadFormDetaljiFail() {
        setLoading(false);
    }

    function save() {
        let stavka = {
            StavkaRacunskeStavkeID : formStavka ? formStavka.StavkaRacunskeStavkeID : -1,
            RacunskaStavkaID : props.racunskaStavkaID,
            KorisnikID : zaposlenik.KorisnikID,
            Korisnik : zaposlenik,
            Datum : datum,
            Opis : opis,
            Tarifa : tarifa,
            Iznos : parseFloat(cijena),
            Kolicina : parseFloat(kolicina),
            FiksnaKolicina : true,
            FirmaValutaID : firmaValuta.FirmaValutaID,
            FirmaValuta : firmaValuta,
            PredmetID : predmet ? predmet.PredmetID : null,
            Predmet : predmet,
            EvidencijaDetaljID : evidencijaDetaljID,
            TipNaplatnostiID : tipNaplatnosti.TipNaplatnostiID,
            TipNaplatnosti : tipNaplatnosti,
            TipOporezivostiID : tipOporezivosti.TipOporezivostiID,
            TipOporezivosti : tipOporezivosti,
            TrajanjeMinuta : formStavka ? formStavka.TrajanjeMinuta : null,
            RacunFirmaValutaID : props.racunFirmaValutaID,
            RacunskaStavkaDatumObracuna : props.RacunskaStavkaDatumObracuna,
//            TipEvidencijeID : formStavka ? formStavka.TipEvidencijeID : 1
            TipEvidencijeID : tipEvidencije.value,
            GUID : formStavka ? formStavka.GUID : Util.generateUUID()
        };
        debugger;
        if ((formStavka && formStavka.StavkaRacunskeStavkeID > 0) || racunskaStavkaID > 0) {
            setLoading(true);
            api.post("stavkaracunskestavke/save", stavka, saveSuccess, saveFail);
        } else if (formStavka && formStavka.StavkaRacunskeStavkeID <= 0) {
            let newStavke = stavke.filter(s => formStavka.GUID != s.GUID);
            newStavke.push(stavka);
            setStavke(newStavke);
            setFormOpened(false);
        } else {
            if (predmet && promijeniCijenuZaPredmetUSvimStavkamaPriSpremanjuRacuna) {
                let newStavke = [];
                stavke.forEach(stavkaRacunskeStavke => {
                    if (stavkaRacunskeStavke.TipEvidencijeID == 1 
                        && stavkaRacunskeStavke.PredmetID == predmet.PredmetID 
                        && (formStavka ? stavkaRacunskeStavke.StavkaRacunskeStavkeID != formStavka.StavkaRacunskeStavkeID : true) 
                        && (promijeniCijenuTipKorisnikaID && !promijeniCijenuKorisnikID ? stavkaRacunskeStavke.Korisnik.TipKorisnikaID == promijeniCijenuTipKorisnikaID : true)
                        && (promijeniCijenuKorisnikID ? stavkaRacunskeStavke.KorisnikID == promijeniCijenuKorisnikID : true)) {
                        let newStavkaRacunskeStavke = {
                            StavkaRacunskeStavkeID : stavkaRacunskeStavke.StavkaRacunskeStavkeID,
                            RacunskaStavkaID : stavkaRacunskeStavke.RacunskaStavkaID,
                            KorisnikID : stavkaRacunskeStavke.KorisnikID,
                            Korisnik : stavkaRacunskeStavke.Korisnik,
                            Datum : stavkaRacunskeStavke.Datum,
                            Opis : stavkaRacunskeStavke.Opis,
                            Tarifa : stavkaRacunskeStavke.Tarifa,
                            Iznos : cijena,
                            FirmaValutaID : firmaValuta.FirmaValutaID,
                            FirmaValuta : firmaValuta,
                            PredmetID : predmet ? predmet.PredmetID : null,
                            Predmet : predmet,
                            EvidencijaDetaljID : stavkaRacunskeStavke.EvidencijaDetaljID,
                            TipNaplatnostiID : tipNaplatnosti.TipNaplatnostiID,
                            TipNaplatnosti : tipNaplatnosti,
                            TipOporezivostiID : tipOporezivosti.TipOporezivostiID,
                            TipOporezivosti : tipOporezivosti,
                            TrajanjeMinuta : stavkaRacunskeStavke.TrajanjeMinuta,
                            RacunFirmaValutaID : stavkaRacunskeStavke.RacunFirmaValutaID,
                            RacunskaStavkaDatumObracuna : stavkaRacunskeStavke.RacunskaStavkaDatumObracuna,
                            TipEvidencijeID : stavkaRacunskeStavke.TipEvidencijeID
                        };
                        newStavke.push(newStavkaRacunskeStavke);
                    } else {
                        newStavke.push(stavkaRacunskeStavke);
                    }
                });
                newStavke.push(stavka);
                setStavke(newStavke);
                let newRacunskeStavke = [];
                props.racunskeStavke.forEach(racunskaStavka => {
                    if (tipDosega.TipDosegaID == 1) {
                        if (racunskaStavka.TipRacunskeStavkeID == 1) {
                            let newStavkeRacunskeStavke = [];
                            racunskaStavka.StavkeRacunskeStavke.forEach(stavkaRacunskeStavke => {
                                if (stavkaRacunskeStavke.TipEvidencijeID == 1 && stavkaRacunskeStavke.PredmetID == predmet.PredmetID && (formStavka ? stavkaRacunskeStavke.StavkaRacunskeStavkeID != formStavka.StavkaRacunskeStavkeID : true)) {
                                    let newStavkaRacunskeStavke = {
                                        StavkaRacunskeStavkeID : stavkaRacunskeStavke.StavkaRacunskeStavkeID,
                                        RacunskaStavkaID : stavkaRacunskeStavke.RacunskaStavkaID,
                                        KorisnikID : stavkaRacunskeStavke.KorisnikID,
                                        Korisnik : stavkaRacunskeStavke.Korisnik,
                                        Datum : stavkaRacunskeStavke.Datum,
                                        Opis : stavkaRacunskeStavke.Opis,
                                        Tarifa : stavkaRacunskeStavke.Tarifa,
                                        Iznos : cijena,
                                        FirmaValutaID : firmaValuta.FirmaValutaID,
                                        FirmaValuta : firmaValuta,
                                        PredmetID : predmet ? predmet.PredmetID : null,
                                        Predmet : predmet,
                                        EvidencijaDetaljID : stavkaRacunskeStavke.EvidencijaDetaljID,
                                        TipNaplatnostiID : tipNaplatnosti.TipNaplatnostiID,
                                        TipNaplatnosti : tipNaplatnosti,
                                        TipOporezivostiID : tipOporezivosti.TipOporezivostiID,
                                        TipOporezivosti : tipOporezivosti,
                                        TrajanjeMinuta : stavkaRacunskeStavke.TrajanjeMinuta,
                                        RacunFirmaValutaID : stavkaRacunskeStavke.RacunFirmaValutaID,
                                        RacunskaStavkaDatumObracuna : stavkaRacunskeStavke.RacunskaStavkaDatumObracuna,
                                        TipEvidencijeID : stavkaRacunskeStavke.TipEvidencijeID
                                    };
                                    newStavkeRacunskeStavke.push(newStavkaRacunskeStavke);
                                } else {
                                    newStavkeRacunskeStavke.push(stavkaRacunskeStavke);
                                }
                            });
                            let newRacunskaStavka = {
                                RacunskaStavkaID : racunskaStavka.RacunskaStavkaID,
                                RacunID : racunskaStavka.RacunID,
                                TipRacunskeStavkeID : racunskaStavka.TipRacunskeStavkeID,
                                TipRacunskeStavkeNaziv : racunskaStavka.TipRacunskeStavkeNaziv,
                                Opis : racunskaStavka.Opis,
                                TipJediniceMjereID : racunskaStavka.TipJediniceMjereID,
                                TipJediniceMjere : racunskaStavka.TipJediniceMjere,
                                Kolicina : racunskaStavka.Kolicina,
                                Cijena : racunskaStavka.Kolicina,
                                Rabat : racunskaStavka.Rabat,
                                Marza : racunskaStavka.Marza,
                                DatumObracuna : racunskaStavka.DatumObracuna,
                                TipNaplatnostiID : racunskaStavka.TipNaplatnostiID,
                                TipNaplatnosti : racunskaStavka.TipNaplatnosti,
                                TipOporezivostiID : racunskaStavka.TipOporezivostiID,
                                TipOporezivosti : racunskaStavka.TipOporezivosti,
                                RedniBroj : racunskaStavka.RedniBroj,
                                TipVrsteStavkeID : racunskaStavka.TipVrsteStavkeID,
                                KontoID : racunskaStavka.KontoID,
                                Konto : racunskaStavka.Konto,
                                PredmetID : racunskaStavka.PredmetID,
                                Predmet : racunskaStavka.Predmet,
                                StavkeRacunskeStavke : newStavkeRacunskeStavke,
                                FirmaValutaID : racunskaStavka.FirmaValutaID,
                                FirmaValuta : racunskaStavka.FirmaValuta,
                                RacunFirmaValutaID : racunskaStavka.RacunFirmaValutaID
                            };
                            newRacunskeStavke.push(newRacunskaStavka);
                        } else {
                            newRacunskeStavke.push(racunskaStavka);
                        }
                    } else {
                        newRacunskeStavke.push(racunskaStavka);
                    }
                });
                props.setRacunskeStavke(newRacunskeStavke);
            } else {
                setStavke(ogStavke => [...ogStavke, stavka]);
            }
            setFormOpened(false);
        }
    }

    function saveSuccess() {
        setLoading(false);
        refresh();
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleKolicinaFocusOut() {
        if (isNaN(kolicina) || kolicina < 0) {
            setKolicina(0);
        } else {
            setKolicina(kolicina);
        }
    }

    function handleCijenaFocusOut() {
        // if (isNaN(cijena) || cijena < 0) {
        //     setCijena(0);
        // } else {
        //     setCijena(Math.ceil(cijena));
        // }
        if (cijena == "" || cijena.charAt(0) == '-') {
            setCijena("0.00");
        } else if (!isNaN(parseFloat(cijena))) {
            let value = cijena;
            let i = 0;
            while (cijena.length > i + 1 && cijena.charAt(i) == '0' && cijena.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            ;
            value = Util.toStringDecimal(value);
            setCijena(value);
        }

    }

    function provjeriPostojeLiStavkeSIstimPredmetom() {
        // prva provjera - radi se o aktivnosti i nešto se promijenilo
        if (tipEvidencije.value == 1 && (cijena != pocetnaCijena || tipNaplatnosti != pocetniTipNaplatnosti || tipOporezivosti != pocetniTipOporezivosti || firmaValuta != pocetnaFirmaValuta)) {
            let postojeStavkeSIstimPredmetom = false;
            let preskociDizanje = false;
            if (predmet) {
                stavke.forEach(stavkaRacunskeStavke => {
                    // tu se traži druge stavke u istoj specifikaciji koje su za isti predmet
                    if (stavkaRacunskeStavke.TipEvidencijeID == 1 && stavkaRacunskeStavke.PredmetID == predmet.PredmetID && (formStavka ? (stavkaRacunskeStavke.StavkaRacunskeStavkeID > 0 ? stavkaRacunskeStavke.StavkaRacunskeStavkeID != formStavka.StavkaRacunskeStavkeID : stavkaRacunskeStavke.GUID != formStavka.GUID) : true)) {
                        // druga provjera - ako su skroz iste po parametrima naplate - ne treba dizati prozor
                        if (parseFloat(stavkaRacunskeStavke.Iznos) != parseFloat(cijena) || stavkaRacunskeStavke.FirmaValutaID != firmaValuta.FirmaValutaID || stavkaRacunskeStavke.TipNaplatnostiID != tipNaplatnosti.TipNaplatnostiID || stavkaRacunskeStavke.TipOporezivostiID != tipOporezivosti.TipOporezivostiID) {
                            postojeStavkeSIstimPredmetom = true;
                        } else {
                            // ako je namješteno da se prozor NE diže ako već postoji stavka s identičnim parametrima naplate, onda na ispunjenje tog uvjeta treba preskočiti otvaranje prozora
                            if (tipProvjerePromjeneCijene == 1) {
                                preskociDizanje = true;
                            }
                        }
                    }
                })
                if (!postojeStavkeSIstimPredmetom) {
                    // tu se traži druge stavke u drugim specifikacijama koje su za isti predmet, nepotrebno ako se nađe već u istoj specifikaciji
                    props.racunskeStavke.forEach(racunskaStavka => {
                        if (racunskaStavka.TipRacunskeStavkeID == 1) {
                            racunskaStavka.StavkeRacunskeStavke.forEach(stavkaRacunskeStavke => {
                                if (stavkaRacunskeStavke.TipEvidencijeID == 1 && stavkaRacunskeStavke.PredmetID == predmet.PredmetID && (formStavka ? (stavkaRacunskeStavke.StavkaRacunskeStavkeID > 0 ? stavkaRacunskeStavke.StavkaRacunskeStavkeID != formStavka.StavkaRacunskeStavkeID : stavkaRacunskeStavke.GUID != formStavka.GUID) : true)) {
                                    // druga provjera - ako su skroz iste po parametrima naplate - ne treba dizati prozor
                                    if (parseFloat(stavkaRacunskeStavke.Iznos) != parseFloat(cijena) || stavkaRacunskeStavke.FirmaValutaID != firmaValuta.FirmaValutaID || stavkaRacunskeStavke.TipNaplatnostiID != tipNaplatnosti.TipNaplatnostiID || stavkaRacunskeStavke.TipOporezivostiID != tipOporezivosti.TipOporezivostiID) {
                                        postojeStavkeSIstimPredmetom = true;
                                    } else {
                                        // ako je namješteno da se prozor NE diže ako već postoji stavka s identičnim parametrima naplate, onda na ispunjenje tog uvjeta idemo direktno na save()
                                        if (tipProvjerePromjeneCijene == 1) {
                                            preskociDizanje = true;
                                        }
                                    }
                                }
                            })
                        }
                    });
                }
            }
            if (postojeStavkeSIstimPredmetom && !preskociDizanje) {
                setPromijeniCijenuZaPredmetUSvimStavkamaAlert(true);
            } else {
                save();
            }
        } else {
            save();
        }
    }
    
    function calculateToolTip(gumb){
        
        let tt = '';

        if(predmet && zaposlenik){
            let valutaNaziv = firmaValute.find(v => v.ValutaID == firmaValuta.ValutaID).FirmaValutaNaziv;
            let porez = tipoviOporezivosti.find(to => to.TipOporezivostiID == tipOporezivosti.TipOporezivostiID);
            let porezNaziv = porez.TipPorezaNaziv + ' (' + porez.Stopa * 100 + ' %)';
            let naplatnostNaziv = tipoviNaplatnosti.find(tn => tn.TipNaplatnostiID == tipNaplatnosti.TipNaplatnostiID).Naziv;
            let tipZaposlenikaNaziv = tipoviKorisnika.find(tk => tk.TipKorisnikaID == zaposlenik.TipKorisnikaID).Naziv;

            tt = 'Za predmet: "' + predmet.Naziv + '" postavi cijenu na vrijednost: ' + cijena + ' ' + valutaNaziv + ', s porezom: ' + porezNaziv + ' i tipom naplatnosti: ' + naplatnostNaziv;

            if(gumb == 1){
                tt = tt + ' za sve zaposlenike';
            }

            if(gumb == 2){
                tt = tt + ' za vrstu zaposlenika: ' + tipZaposlenikaNaziv;
            }

            if(gumb == 3){
                tt = tt + ' za zaposlenika: ' + zaposlenik.Ime + ' ' + zaposlenik.Prezime;
            }

            if(gumb == 4){
                tt = tt + ' isključivo na ovoj stavci ove odabrane specifikacije.';
            }
            else{
                if(tipDosega.TipDosegaID == 1){
                    tt = tt + ', i to na svim eventualno postojećim specifikacijama na ovom računu.'
                }
                else{
                    tt = tt + ' isključivo na trenutno odabranoj specifikaciji računa.'
                }
    
            }

        }
        return tt;
    }


    useEffect(() => {
        if (predmet && !klijent) {
            klijenti.forEach(k => {
                if (k.KlijentID == predmet.KlijentID) {
                    setKlijent(k);
                }
            });
        }
    }, [predmet, klijent, klijenti]);

    function handlePredmetOrZaposlenikFocusOut(changedPredmet, changedZaposlenik, changedTipEvidencije) {
        let p = predmet;
        let z = zaposlenik;
        let e = tipEvidencije;
        if(changedPredmet) p = changedPredmet;
        if(changedZaposlenik) z = changedZaposlenik;
        if(changedTipEvidencije) e = changedTipEvidencije;

        // if (predmet && zaposlenik) {
        //     setTipNaplateID(predmet.TipNaplateID);
        //     setLoading(true);
        //     let resource = {
        //         PredmetID : predmet.PredmetID,
        //         TipKorisnikaID : zaposlenik.TipKorisnikaID
        //     }
        //     api.post("cjenik/getcjenik", resource, loadCjenikSuccess, loadCjenikFail);
        // } else {
        //     setCijena("0.00");
        // }
        if (e && e.value == 2) {
            setKolicina(1);
        }

        if(p){
            setTipNaplateID(p.TipNaplateID);
            if(p.TipNaplateID == 2){
                setKolicina(1);
            }
        }
        //po cjenik se ide isključivo ukoliko je, uz definiranog zaposlenika i predmet, tip naplate = "satnica" i tip evidencije = "aktivnost"
        if (p && z && p.TipNaplateID == 1 && e && e.value == 1) {
            let resource = {
                PredmetID : p.PredmetID,
                TipKorisnikaID : z.TipKorisnikaID
            }
            setLoading(true);
            api.post("cjenik/getcjenik", resource, loadCjenikSuccess, loadCjenikFail);

        } 
        // else {
        //     setCijena("0.00");
        // }


    }

    useEffect(() => {
        if (!klijent || (klijent && predmet && klijent.KlijentID != predmet.KlijentID)) {
            setPredmet(null);
        }
    }, [klijent]);

    function loadCjenikSuccess(data) {
        if (data.Iznos != null) {
            var izn = Util.toStringDecimal(data.Iznos);
            setCijena(izn);
        }
        if (data.FirmaValuta != null) {
            setFirmaValuta(data.FirmaValuta);
        }
        setTipNaplatnosti(data.TipNaplatnosti);
        setTipOporezivosti(data.TipOporezivosti);
        setLoading(false);
    }

    function loadCjenikFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        let checkValid = zaposlenik && datum && !isNaN(new Date(datum)) && firmaValuta && tipNaplatnosti && tipOporezivosti && opis && predmet && klijent && (tipNaplateID != 2 || (tarifa != null && tarifa != '')) && cijena != null && cijena != '' && !isNaN(parseFloat(cijena)) && cijena >= 0 && tipEvidencije && kolicina && kolicina > 0;
        setValid(checkValid);
    }, [zaposlenik, datum, firmaValuta, tipNaplatnosti, tipOporezivosti, opis, predmet, klijent, tarifa, tipNaplateID, cijena, tipEvidencije, kolicina]);

    function promijeniCijenuZaPredmetUSvimStavkama(sveVrsteZaposlenika, korisnikID) {
        //najlaksi slucaj - racun je spremljen, pa se moze odmah na backend promijeniti cijenu u ostalim stavkama
        if (racunID > 0) {
            let resource = {
                RacunID : racunID,
                PredmetID : predmet.PredmetID,
                Cijena : cijena,
                FirmaValutaID : firmaValuta.FirmaValutaID,
                TipOporezivostiID : tipOporezivosti.TipOporezivostiID,
                TipNaplatnostiID : tipNaplatnosti.TipNaplatnostiID,
                TipKorisnikaID : sveVrsteZaposlenika ? null : zaposlenik.TipKorisnikaID,
                RacunskaStavkaID : tipDosega.TipDosegaID == 1 ? null : racunskaStavkaID,    //ako je tipDosega = "Sve specifikacije" onda na backend šaljemo null, inače šaljemo upravo ID ove računske stavke
                KorisnikID : korisnikID
            }
            api.post("racunskastavka/promijenicijenestavkamasistimpredmetom", resource, promijeniCijenuSuccess, promijeniCijenuFail);
        } else {   // tezi slucaj - treba spremiti instrukcije za promjenu cijene u ostalim stavkama u stateove, kako bi se kasnije pri spremanju racuna promijenile cijene u ostalim stavkama
            setPromijeniCijenuZaPredmetUSvimStavkamaPriSpremanjuRacuna(true);
            setPromijeniCijenuTipKorisnikaID(sveVrsteZaposlenika ? null : zaposlenik.TipKorisnikaID);
            setPromijeniCijenuKorisnikID(korisnikID);
            setPromijeniCijenuZaPredmetUSvimStavkamaAlert(false);
        }
    };

    function promijeniCijenuSuccess() {
        setPromijeniCijenuZaPredmetUSvimStavkamaAlert(false);
        save();
    }

    function promijeniCijenuFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleTipEvidencijeChange(e, v){
        if(v){
            setTipEvidencije(v);
        }
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formStavka ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddIcon fontSize="medium" style={{marginRight:"15px"}}></AddIcon>
                            )}
                            {formStavka ? (
                                "Uređivanje stavke specifikacije"
                            ) : (
                                "Nova stavka specifikacije"
                            )}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} style={{paddingTop:"8px"}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            label="Datum"
                            value={datum}
                            inputFormat="dd.MM.yyyy"
                            onChange={(v) => {if (v) setDatum(v)}}
                            maxDate={new Date()}
                            renderInput={(params) => <TextField style={{width:"99%"}} {...params} size="small" error={!datum || isNaN(new Date(datum))} required />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={fiksnaNaplataKlijentID ? klijenti.filter(k => k.KlijentID == fiksnaNaplataKlijentID) : klijenti}
                            value={klijent}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {setKlijent(v)}}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} error={klijent == null} style={{width:"99%"}} label="Klijent" size="small" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField style={{marginRight:"5px"}} value={opis} error={!opis} multiline rows={2} onChange={e => setOpis(e.target.value)} label="Opis"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={fiksnaNaplataPredmetID ? predmeti.filter(p => p.PredmetID == fiksnaNaplataPredmetID) : (klijent ? predmeti.filter(p => p.KlijentID == klijent.KlijentID) : predmeti)}
                            value={predmet}
                            autoHighlight
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            //onChange={(e,v) => {setPredmet(v)}}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            onChange={(e, v) => {setPredmet(v); handlePredmetOrZaposlenikFocusOut(v, null, null);}}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"predmet" + option.PredmetID} style={{color: option.TipStanjaPredmetaID != 1 ? "red" : (option.TipNaplateID == 1 ? "black" : ((option.TipNaplateID == 2 ? "magenta" : "green")))}}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} 
                                error={predmet == null} 
                                style={{width:"99%"}} 
                                label="Predmet" 
                                size="small" 
                                sx={{
                                        input: {
                                            color:
                                                predmet && predmet.TipStanjaPredmetaID != 1
                                                    ? "red"
                                                    : predmet && predmet.TipNaplateID == 3
                                                    ? "green"
                                                    : predmet && predmet.TipNaplateID == 2
                                                    ? "magenta"
                                                    : "black",
                                        },
                                    }}
                                />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={zaposlenici.filter(z => z.TipStatusaKorisnikaID == 1)}
                            autoHighlight
                            value={zaposlenik}
                            getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                            //onChange={(e,v) => {if (v) setZaposlenik(v)}}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            onChange={(e,v) => {setZaposlenik(v); handlePredmetOrZaposlenikFocusOut(null, v, null);}}
                            renderInput={(params) => <TextField {...params} style={{width:"99%"}} label="Korisnik" size="small" error={!zaposlenik} required />}
                        />
                    </FormControl>
                </Grid>

                {tipEvidencijeVisible ? (
                    <Grid item xs={6} sm={6} md={tipEvidencije && tipEvidencije.value == 2 ? 6 : 3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                //disableClearable
                                options={tipEvidencijeOptions}
                                autoHighlight
                                value={tipEvidencije}
                                getOptionLabel={(option) => option.naziv}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} >
                                            <Brightness1Icon sx={{ color: option.value == 1 ? "rgb(255,0,255)" : "darkblue"}}></Brightness1Icon>
                                            <span>&nbsp;&nbsp;{option.naziv}</span>
                                        </li>
                                    );
                                }}                                
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                onChange={(e,v) => {setTipEvidencije(v); handlePredmetOrZaposlenikFocusOut(null, null, v);}}
                                //renderInput={(params) => <TextField {...params} style={{width:"99%"}} label="Tip evidencije" size="small" error={!tipEvidencije} required />}
                                renderInput={(params) => (
                                <TextField sx={{width:"99%"}} {...params} label="Tip evidencije" error={!tipEvidencije} required InputProps={{
                                        ...params.InputProps,
                                        startAdornment: tipEvidencije ? <InputAdornment position="end"><Brightness1Icon sx={{ color: tipEvidencije.value == 1 ? "rgb(255,0,255)" : "darkblue"}}></Brightness1Icon></InputAdornment> : null
                                    }} size="small" />
                                )}
                            />
                        </FormControl>
                    </Grid>
                ) : null }

                {!tipEvidencijeVisible || (tipEvidencije && tipEvidencije.value == 1) ? (
                    tipNaplateID == 1 || tipNaplateID == 3 ? (
                        <Grid item xs={6} sm={6} md={3}>
                            <FormControl fullWidth>
                                <TextField type="number" value={kolicina} label="Količina" size="small" onChange={e => setKolicina(e.target.value)} error={kolicina <= 0} onBlur={handleKolicinaFocusOut} ></TextField>    
                            </FormControl>
                        </Grid>
                    ) : (
                        <Grid item xs={6} sm={6} md={3}>
                            <FormControl fullWidth>
                                <TextField value={tarifa || ''} inputProps={{ maxLength: 500 }} label="Tarifa" size="small" onChange={e => setTarifa(e.target.value)} ></TextField>    
                            </FormControl>
                        </Grid>
                    )
                ) : null}

                <Grid item xs={6} sm={6} md={3}>
                    <FormControl fullWidth>
                        <TextField type="number" value={cijena} label={tipNaplateID == 1 ? "Cijena" : "Iznos"} style={{width:"98%"}} size="small" onChange={e => setCijena(e.target.value)} error={cijena < 0} onBlur={handleCijenaFocusOut} required></TextField>    
                    </FormControl>
                </Grid>
                <Grid item xs={tipEvidencijeVisible ? 6 : 12} sm={tipEvidencijeVisible ? 6 : 12} md={tipEvidencijeVisible ? 3 : 6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={firmaValute}
                            value={firmaValuta}
                            autoHighlight
                            disabled={predmet && predmet.Cap}
                            getOptionLabel={(option) => option.FirmaValutaNaziv}
                            onChange={(e,v) => {if (v) setFirmaValuta(v)}}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => <TextField {...params} style={{width:"99%"}} label="Valuta" size="small" error={!firmaValuta} required/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={tipoviNaplatnosti}
                            value={tipNaplatnosti}
                            autoHighlight
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setTipNaplatnosti(v)}}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} >
                                        <Brightness1Icon sx={{ color: "rgb(" + (option.TipNaplatnostiID == 1 ? tipNaplatnostiNaplatnoBoja : tipNaplatnostiNenaplatnoBoja) + ")"}}></Brightness1Icon>
                                        <span>&nbsp;&nbsp;{option.Naziv}</span>
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField sx={{width:"99%"}} {...params} label="Tip naplatnosti" error={!tipNaplatnosti} required InputProps={{
                                    ...params.InputProps,
                                    startAdornment: tipNaplatnosti ? <InputAdornment position="end"><Brightness1Icon sx={{ color : "rgb(" + (tipNaplatnosti.TipNaplatnostiID == 1 ? tipNaplatnostiNaplatnoBoja : tipNaplatnostiNenaplatnoBoja) + ")"}}></Brightness1Icon></InputAdornment> : null
                                }} size="small" />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={tipoviOporezivosti}
                            value={tipOporezivosti}
                            autoHighlight
                            getOptionLabel={(option) => option.TipPorezaNaziv + " (" + option.Stopa * 100 + " %)"}
                            onChange={(e,v) => {if (v) setTipOporezivosti(v)}}
                            renderInput={(params) => <TextField {...params} style={{width:"99%"}} label="Tip oporezivosti" size="small" error={!tipOporezivosti} required/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Tooltip title={!valid ? "Nisu dobro uneseni svi podaci." : ""}>
                            <span><Button onClick={provjeriPostojeLiStavkeSIstimPredmetom} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !valid || takeALook}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <DoneOutlineIcon style={{marginRight:"10px"}} />} Prihvati</Button></span>
                        </Tooltip>
                        <Button variant="outlined" color="error" onClick={() => setFormOpened(false)} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {/* {promijeniCijenuZaPredmetUSvimStavkamaAlert ? <AlertDialog message={} cancelLabel={"Nemoj promijeniti cijenu u ostalim stavkama"} confirmLabel={"Promijeni cijenu u svim stavkama"} cancelAction={} confirmAction={() => promijeniCijenuZaPredmetUSvimStavkama()}></AlertDialog> : null} */}

            <Modal open={promijeniCijenuZaPredmetUSvimStavkamaAlert}>
                <Box sx={style}>
                    <Typography variant="h8" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <div>Dodajete novu stavku ili ste promijenili cijenu i/ili valutu i/ili tip naplatnosti i/ili tip oporezivosti postojeće stavke.</div>
                        <div>Želite li promijeniti parametre cijene (izjednačiti s upravo upisanim) u drugim stavkama vezanim za predmet "{predmet ? predmet.Naziv : ""}"?</div>
                    </Typography> 
                    <FormControl fullWidth style={{paddingTop:'1em', paddingBottom:'1em'}}>
                        <Autocomplete
                            disablePortal
                            options={tipoviDosega}
                            value={tipDosega}
                            autoHighlight
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setTipDosega(v)}}
                            renderInput={(params) => <TextField {...params} style={{width:"99%"}} label="Tip dosega" size="small" error={!tipDosega} required/>}
                        />
                    </FormControl>
                    {/* <Tooltip title={() => calculateToolTip(1)} arrow followCursor> */}
                    <Tooltip title={calculateToolTip(1)} arrow followCursor>
                        <Button variant="contained" onClick={() => promijeniCijenuZaPredmetUSvimStavkama(true, null)} style={{width:"100%", marginBottom:"5px"}}>Promijeni parametre cijene u svim stavkama za sve tipove korisnika</Button>
                    </Tooltip>
                    <Tooltip title={calculateToolTip(2)} arrow followCursor>
                        <Button variant="contained" onClick={() => promijeniCijenuZaPredmetUSvimStavkama(false, null)} style={{width:"100%", marginBottom:"5px"}}>Promijeni parametre cijene u svim stavkama samo za trenutni tip korisika</Button>
                    </Tooltip>
                    <Tooltip title={calculateToolTip(3)} arrow followCursor>
                        <Button variant="contained" onClick={() => promijeniCijenuZaPredmetUSvimStavkama(false, zaposlenik ? zaposlenik.KorisnikID : null)} style={{width:"100%", marginBottom:"5px"}}>Promijeni parametre cijene u svim stavkama samo za trenutnog korisnika</Button>
                    </Tooltip>
                    <Tooltip title={calculateToolTip(4)} arrow followCursor>                    
                        <Button style={{width:"100%", marginBottom:"5px"}} variant="outlined" onClick={() => save()}>Nemoj promijeniti cijenu u ostalim stavkama</Button>
                    </Tooltip>                    
                    <Tooltip title={'Vrati se nazad na izmjene ove stavke.'} arrow followCursor>                    
                        <Button style={{width:"100%"}} color="error" variant="outlined" onClick={() => setPromijeniCijenuZaPredmetUSvimStavkamaAlert(false)}>Odustani</Button>
                    </Tooltip>                                    
                </Box>
            </Modal>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default StavkaRacunskeStavkeForm;